import { ClientService, RequestMethod } from "../client-service";
import { RequestResponseLogic } from "../Ui/request-response";

const WORKFLOW_SCHEMA = '/workflow'
const WORKFLOW_INSTANCE = '/workflow/instance'
const WORKFLOW_INSTANTIATE = '/workflow/instantiate'
const WORKFLOW_START = '/workflow/start'
const WORKFLOW_ABORT = '/workflow/abort'
const PROGRESS = "progress"

export const WorkflowLogic = {
    //kiosztható folyamat schema lista
    async getWorkflowSchemaList(params) {
        // starting request
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            WORKFLOW_SCHEMA,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //példányosított (kiosztott) folyamatok listája
    async getWorkflowInstanceList(params) {
        // starting request
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            WORKFLOW_INSTANCE,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //folyamat példányosítása
    async setInstantiate(body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            WORKFLOW_INSTANTIATE,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async startWorkflow(instanceId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.POST,
            WORKFLOW_START + '/' + instanceId,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getWorkflowProgress(instanceId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            WORKFLOW_INSTANCE + '/' + instanceId + '/' + PROGRESS,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async abortWorkflow(instanceId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.POST,
            WORKFLOW_ABORT + '/' + instanceId,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    }
}